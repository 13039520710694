import React from 'react'

import Layout from '@components/Layout'
import SEO from '@components/SEO'
const Embed = () => (
  <div style={{ width: '100%', height: '100vh' }}>
    <iframe
      title="omnify"
      id="omnify-widget-ifrm"
      src="https://radioradio.getomnify.com/widgets/PQC2T191IKBHZQNRYYN07"
      height="100%"
      width="100%"
      frameBorder={0}
    ></iframe>
  </div>
)

const ClubhousePage = () => (
  <Layout>
    <SEO title="Clubhouse" />
    <Embed />
  </Layout>
)

export default ClubhousePage
